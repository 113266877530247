* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}


body {
	font-family: "Nunito";
	background-color: #fff;
}

.container-section {
	margin-bottom: 20px;
}

.form-progress {
	min-height: 100vh;
	max-height: 100%;
}

.card-container {
	width: 25%;
	float: left;
}

.card-container .title-section {}

.container-section .card-section {
	display: inline-block;
	width: 100%;
}

.card-container .card-content h2 {
	color: #12284c;
	font-size: 15px;
}

.card-container .card-content .last-view {
	text-decoration: none;
	color: #12284cbf;
	font-size: 13px;
	font-weight: 600;
}

.card-container .card-content p {
	color: #12284c78;
	font-size: 14px;
}

.card-container .card-body {
	height: 150px;
	display: flex;
	background: #fff;
	padding: 26px 20px;
	margin: 8px 24px 14px 24px;
	align-items: center;
	box-shadow: 0px 4px 5px 1px #00000017;
	border-radius: 12px;
	text-decoration: none;
	border: 1px solid #00000012;
	transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
		0.3s box-shadow,
		0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.card-container .card-body:hover {
	transform: scale(1.05);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-container .card-body .img-part {
	margin-right: 10px;
	width: 90px;
	height: 90px;
}

.card-container .card-body .img-part img {
	width: 100%;
}

.container-section .left-section {
	margin: 8px 24px;
}

.left-section {
	display: inline-flex;
}

.left-section label {
	color: #12284c;
	font-size: 24px;
	font-weight: 900;
}

.left-section img {
	width: 36px;
	margin-right: 8px;
}

@media (min-width: 1410px) and (max-width: 1490px) {
	.card-container {
		width: 25%;
	}
}

@media (min-width: 1024px) and (max-width: 1409px) {
	.card-container {
		width: 33%;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.card-container {
		width: 50%;
	}
}

@media (max-width: 767px) {
	.card-container {
		width: 100%;
	}

	.card-container .card-body {
		height: auto;
	}
}

/* login css  */
/* config.css */

:root {
	--baseColor: #ffffff;
}

.login-layout .form-title {
	font-size: 24px;
	font-weight: 700;
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	margin-bottom: 16px;
	text-transform: uppercase;
	color: #fff;
}

.login-bg-cover {
	position: absolute;
	width: 100%;
	top: 0;
	height: 100%;
	background-image: url("../Images/login-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

/* helpers/align.css */

.login-layout .align {
	display: grid;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-items: center;
	place-items: center;
}

.login-layout .brand-logo {
	text-align: center;
	margin-bottom: 3rem;
}

.brand-logo img {
	width: 250px;
}

svg.iconRight {
	width: 23px;
	fill: #fff;
	height: 20px;
}

.login-layout .grid {
	/* width: 91%;  */
	margin-left: auto;
	margin-right: auto;
	max-width: 24rem;
	margin-top: 5rem;
	background: #00000052;
	border-radius: 22px;
	padding: 25px 10px;
	backdrop-filter: blur(5px);
}

/* helpers/hidden.css */

.login-layout .hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* helpers/icon.css */

:root {
	--iconFill: var(--baseColor);
}

.login-layout .icons {
	display: none;
}

.login-layout .icon {
	height: 1em;
	display: inline-block;
	fill: #ffffff;
	fill: var(--iconFill);
	width: 1em;
	vertical-align: middle;
}

.login-layout a {
	color: #fff !important;
	outline: 0;
	text-decoration: none;
}

.login-layout a:focus,
.login-layout a:hover {
	text-decoration: underline;
}

/* modules/form.css */

:root {
	--formGap: 0.875rem;
}

.login-layout input {
	background-image: none;
	border: 0;
	color: inherit;
	font: inherit;
	margin: 0;
	outline: 0;
	padding: 0;
	-webkit-transition: background-color 0.3s;
	-o-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.login-layout input[type="submit"] {
	cursor: pointer;
}

.login-layout .form {
	display: grid;
	grid-gap: 0.875rem;
	gap: 0.875rem;
	grid-gap: var(--formGap);
	gap: var(--formGap);
}

.login-layout .form input[type="password"],
.login-layout .form input[type="text"],
.login-layout .form input[type="submit"] {
	width: 100%;
}

.login-layout .form__field {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.login-layout .form__input {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

/* modules/login.css */

:root {
	--loginBorderRadus: 0.25rem;
	--loginColor: #eee;

	--loginInputBackgroundColor: #415370;
	--loginInputHoverBackgroundColor: #434a52;

	--loginLabelBackgroundColor: #12284c;

	--loginSubmitBackgroundColor: #12284c;
	--loginSubmitColor: #eee;
	--loginSubmitHoverBackgroundColor: #12284c;
}

.login-layout .login {
	color: #eee;
	color: var(--loginColor);
}

.login-layout input::placeholder {
	color: #fff;
}

.login-layout .login label,
.login-layout .login input[type="text"],
.login-layout .login input[type="password"],
.login-layout .login input[type="submit"] {
	border-radius: 0.25rem;
	border-radius: var(--loginBorderRadus);
	padding: 1rem;
}

.login-layout .login label {
	background-color: #363b41;
	background-color: var(--loginLabelBackgroundColor);
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	padding-left: 1.25rem;
	padding-right: 1.25rem;
	height: 100%;
}

.login-layout .login input[type="password"],
.login-layout .login input[type="text"] {
	background-color: #3b4148;
	background-color: var(--loginInputBackgroundColor);
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.login-layout .login input[type="password"]:focus,
.login-layout .login input[type="password"]:hover,
.login-layout .login input[type="text"]:focus,
.login-layout .login input[type="text"]:hover {
	background-color: #434a52;
	background-color: var(--loginInputHoverBackgroundColor);
}

.login-layout .login input[type="submit"] {
	background-color: #ea4c88;
	background-color: var(--loginSubmitBackgroundColor);
	color: #eee;
	color: var(--loginSubmitColor);
	font-weight: 700;
	text-transform: uppercase;
}

.login-layout .login input[type="submit"]:focus,
.login-layout .login input[type="submit"]:hover {
	background-color: #d44179;
	background-color: var(--loginSubmitHoverBackgroundColor);
}

/* modules/text.css */

.login-layout p {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	color: #fff;
}

.login-layout .text--center {
	text-align: center;
}

.text-none {
	text-transform: none !important;
}

.ant-switch-checked {
	background: #263a5be6 !important;
}

.rounded-4 {
	border-radius: 1rem;
}

.shadow-common {
	box-shadow: inset 3px 2px 5px #0000002e, inset -4px -4px 5px #ffffff80;
	border: 1px solid #acbfd5 !important;
}

.btn-link-common {
	color: #007bff;
	font-weight: 400;
	background-color: transparent;
	border: 0px solid transparent;
	cursor: pointer;
}

.dotted-bottom {
	border-bottom: 0.5px dashed #acbfd5;
}

.badge-good {
	background-color: #cfc;
	color: #064;
	border-radius: 4px;
	padding: 3px;
}

.badge-warn {
	background-color: #ffC107;
	color: #000;
	border-radius: 4px;
	padding: 3px;
}

.badge-error {
	background-color: #DC3545;
	color: #FFF;
	border-radius: 4px;
	padding: 3px;
}


tr.bad {
	background-color: #DC3545 !important;
}

tr.good {
	background-color: #cfc !important;
}

tr.warn {
	background-color: #ffC107 !important;
}