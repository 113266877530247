@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
body {
  margin: 0;
  /* font-family: "Roboto", "sans-serif"; */
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
  background-color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}

/* Slide Fade Up */

.slide-fadein-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fadein-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.4s;
}

.slide-fadein-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fadein-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.4s;
}
