
 
 
 .custom-file-label
 {
	 border: unset;
 }
 
 .intervention-form .custom-file-label i 
 {
	 float: right;
 }
 .custom-file-label::after
 {
	 display: none;
 }
  
 
 @media (min-width: 576px)
 {
 .modal-dialog {
	 /* max-width: 800px; */
	 margin: 1.75rem auto;
 }
 }
 
 .float-top-right{
	position: absolute;
	right: 1rem;
	top: 1rem;
 }

 .rdg-cell-error{
    border: 3px solid red !important;
    border-bottom-width: 1.5px !important;
    border-top-width: 1.5px !important;
    background-color: #00000000 !important;
 }

 
 .final-container{
	display: flex;
	height: 75vh;
	width: 100%;
	margin-bottom: 1rem;
 }
 .final-container .part{
	width: 50%;
	height: 100%;
 }
 .final-container .text{
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
 }
 .final-container .bg-danger{
	background-color: #BD0404;
	color: #fff;
 }
 .final-container .bg-success{
	background-color: #00FF9F;
	color: #fff;
 }