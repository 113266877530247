 .imgMask {
	 position: relative;
	 width: 200px;
	 height: 200px;
	 border: 1px solid black;
	 cursor: pointer;
 }
 .imgMask-content {
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 transform: translate(-50%, -50%);
	 font-size: 40px;
 }
 .imgg{
	 position: absolute;
	 width: 200px;
	 height: 200px;
	 top: 0;
	 left: 0;
	 opacity: 0;
	 cursor: pointer;
 }
 
 #sub-domain-img{
   position: absolute;
	 width: 202px;
	 height: 200px;
	 top: 0;
	 z-index: -1;
 }
 
 .custom-file
 {
	 justify-content: space-between;
	 display: inline-block !important;
 
 }
 .custom-file-label
 {
	 border: unset;
 }
 
 .intervention-form .custom-file-label i 
 {
	 float: right;
 }
 .custom-file-label::after
 {
	 display: none;
 }
  
 #custom-file{
   padding: 4px 4px;
   border: 2px solid #3b4d6b;
   border-radius: 5px;
   font-size: 12px;
   background:  #3b4d6b;
   cursor: pointer;
   color: #fff;
   height: 38px;
 }
 
 #custom-file:hover{
   background:#808000;
   color: #fff;
 }
 
 #custom-file span{
   margin-left: 10px;
 }
 
 
 
 @media (min-width: 576px)
 {
 
 #addSubDomain .modal-dialog {
	 max-width: 900px;
	 margin: 1.75rem auto;
 }
 }