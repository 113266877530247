@import '~antd/dist/antd.css';
.btn-link {
	color:#263a5be6 !important;
}
.btn-link :hover{
	color:#263a5b !important;
}
.btn-link .fas.fa-times{
	
	color:#263a5b !important;
}
.ant-steps-item-finish .ant-steps-item-icon{
	border-color:#263a5be6 !important;
}
.ant-steps-navigation .ant-steps-item::before{
	background-color:#263a5be6 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
	background:#263a5be6 !important;
    border-color: #263a5be6 !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
	color: #263a5be6 !important;

}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
	color: #172B4D !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: #172B4D !important;
}

.ant-tabs-ink-bar{
	background:#263a5be6 !important;
}

.shadow{
    box-shadow: 0 0 10px #00000020;
}

.ant-tabs-tab:hover,
.ant-tabs-tab-btn:hover,
.ant-tabs-tab:focus,
.ant-tabs-tab-btn:focus,
.ant-steps-item-container:hover .ant-steps-item-content,
.ant-steps-item-wait:hover >.ant-steps-item-content,
.ant-steps-item-container:focus .ant-steps-item-content{
	color: #263a5b !important;
}