 .imgMask {
	 position: relative;
	 width: 200px;
	 height: 200px;
	 border: 1px solid black;
	 cursor: pointer;
 }
 .imgMask-content {
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 transform: translate(-50%, -50%);
	 font-size: 40px;
 }
 .imgg{
	 position: absolute;
	 width: 200px;
	 height: 200px;
	 top: 0;
	 left: 0;
	 opacity: 0;
	 cursor: pointer;
 }
 
 #sub-domain-img{
   position: absolute;
	 width: 202px;
	 height: 200px;
	 top: 0;
	 z-index: -1;
 }
 
 
 .flex {
   display: flex;
 }
 .wrap {
   flex-wrap: wrap;
 }
 
 .upload__list {
   position: relative;
   padding: 5px 15px;
   border: 1px solid #ececec;
 }
 .upload__info {
   height: 80px;
   border-bottom: 1px solid #ececec;
   flex-direction: row;
   align-items: center;
 }
 .upload__info:last-child {
   border: none;
 }
 
 .upload__img {
   width: 80px;
   height: 80%;
   margin-right: 10px;
   object-fit: cover;
   -o-object-fit: cover;
 }
 
 .upload__progress {
   background: #ddd;
   height: 7px;
   width: 100%;
   margin-top: 10px;
 }
 .upload__bar {
 
   background-image: linear-gradient(
	 45deg,
	 rgba(255, 255, 255, 0.15) 25%,
	 transparent 25%,
	 transparent 50%,
	 rgba(255, 255, 255, 0.15) 50%,
	 rgba(255, 255, 255, 0.15) 75%,
	 transparent 75%,
	 transparent
   );
 
   background-size: 1rem 1rem;
   width: 50%;
   height: 100%;
   background-color: #3695db;
   transition: all 0.3s ease;
 }
 .w-100 {
   width: 100%;
 }
 .upload__name {
   margin: 7px 5px 0px 0px;
 }
 .upload__size {
   margin: 7px 5px 0px 5px;
 }
 .upload__long {
   margin: 7px 0px 0px 5px;
   font-weight: bold;
 }
 .upload__btn {
   text-decoration: none;
   color: #fff !important;
   background: #3b4d6b;
	 padding: 7px 15px;
	 border-radius: 50px;
 }
 .upload__delete {
   margin-left: auto;
 }
 .upload__input {
   position: absolute;
   top: 0;
   right: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   cursor: pointer;
 }
 .upload__drop {
   position: relative;
   /* border: 2px dotted #3695db; */
   padding: 15px 10px;
   overflow: hidden;
   margin-bottom: 10px;
   cursor: pointer;
 }
 