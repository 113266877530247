 .imgMask {
	 position: relative;
	 width: 200px;
	 height: 200px;
	 border: 1px solid black;
	 cursor: pointer;
 }
 .imgMask-content {
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 transform: translate(-50%, -50%);
	 font-size: 40px;
 }
 .imgg{
	 position: absolute;
	 width: 200px;
	 height: 200px;
	 top: 0;
	 left: 0;
	 opacity: 0;
	 cursor: pointer;
 }
 
 #sub-domain-img{
   position: absolute;
	 width: 202px;
	 height: 200px;
	 top: 0;
	 z-index: -1;
 }
 
 .custom-file-label
 {
	 border: unset;
 }
 
 .intervention-form .custom-file-label i 
 {
	 float: right;
 }
 .custom-file-label::after
 {
	 display: none;
 }
  
 .file-upload{
	 /* height:100px;
	 width:100px;
	 margin:40px auto;
	 border:1px solid #f0c0d0;
	 border-radius:100px;
	 overflow:hidden;
	 position:relative; */
	 /* height: 100px;  */
	 /* width: 100px; */
	 /* margin: 40px auto; */
	 /* border: 1px solid #f0c0d0; */
	 /* border-radius: 100px; */
	 /* overflow: hidden;
	 position: relative; */
	 
 }
 .file-upload span
 {
	 float: right;
	 margin-right: -286px;
	 z-index: -1;
	 margin-top: -30px;
 
 
 }
 .file-upload input{
	 /* position:absolute;
	 height:400px;
	 width:400px;
	 left:-200px;
	 top:-200px;
	 background:transparent;
	 opacity:0;
	 -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	 filter: alpha(opacity=0);   */
 
	 /* position: absolute; */
	 float: right;
	 height: auto;
	 width: auto;
	 /* left: -200px; */
	 /* top: -200px; */
	 background: transparent;
	 opacity: 0;
	 -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
	 filter: alpha(opacity=0);
	 margin-top: -27px;
	 z-index: 999999;
 }
 
 .file-upload img{
	 /* height:70px;
	 width:70px;
	 margin:15px; */
 }
 
  .opacity-50
  {
	 opacity: .5!important;
  }
 
 @media (min-width: 576px)
 {
 .modal-dialog {
	 max-width: 800px;
	 margin: 1.75rem auto;
 }
 }
 