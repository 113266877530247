$side-height: calc("100vh"-"58px");

.navbar-brand img {
  object-fit: contain;
  height: 32px;
  padding: 5px 0px 5px 0px;
}

.pro-sidebar {
  -webkit-box-shadow: 2px 0 1px -1px #999 !important;
  -moz-box-shadow: 2px 0 1px -1px #999 !important;
  box-shadow: 2px 0 1px -1px #999 !important;
  z-index: 99 !important;
}
.pro-sidebar > .pro-sidebar-inner {
  // background: #FAFBFC !important;
  background: #fff !important;
  height: 100vh !important;
  position: relative !important;
  z-index: 101;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  border-bottom: none !important;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow: visible !important;
}
.menu-chevron {
  z-index: 1000;
  cursor: pointer;
  padding: 7px 10px;
  position: absolute;
  right: -17px;
  top: 17px;
  background-color: #fff;
  display: flex;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #00000020;
  border-radius: 50px;
  color: #263a5be6;
  opacity: 0;
  transition: all 0.5s;
  transform: rotateZ(0deg);
  transition-delay: 0.5s;
}
.pro-sidebar :hover {
  .menu-chevron {
    opacity: 1 !important;
    transition: all 1s;
  }
}
.pro-sidebar .collapsed {
  .menu-chevron {
    opacity: 1 !important;
    transition: all 1s;
  }
}

.menu-chevron.toggle {
  opacity: 1 !important;
  i {
    transform: rotateZ(180deg);
    transition: all 0.5s;
  }
}
.pro-sidebar .pro-menu {
  overflow: scroll !important;
  height: calc(100vh - 51px);
}
.pro-sidebar
  .pro-menu.shaped
  .pro-menu-item
  > .pro-inner-item
  > .pro-icon-wrapper {
  background-color: transparent !important;
}
.pro-icon {
  color: #263a5be6 !important;
}
.topic {
  // padding: 8px 10px !important;
  color: #6b778c !important;
  padding: 8px 35px 8px 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.45455;
  text-transform: uppercase;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  .fa-chevron-down {
    transform: rotateZ(0deg);
    transition: all 0.3s ease-in-out;
  }
}

.topic.active {
  border-bottom: none;
  color: #172b4d;
  .fa-chevron-down {
    transform: rotateZ(180deg);
    transition: all 0.3s ease-in-out;
  }
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content {
  color: #6b778c;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 1.2;
  font-size: 14px;
}
@import "~react-pro-sidebar/dist/scss/styles.scss";

.pro-sidebar .pro-menu .pro-menu-item.active .pro-inner-item {
  border: #172b4d solid;
  border-width: 0px 0px 0px 5px;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 0px !important;
  // padding: 8px 35px 8px 20px;
  margin: 8px 35px 8px 20px !important;
}
