 .imgMask {
	 position: relative;
	 width: 200px;
	 height: 200px;
	 border: 1px solid black;
	 cursor: pointer;
 }
 .imgMask-content {
	 position: absolute;
	 left: 50%;
	 top: 50%;
	 transform: translate(-50%, -50%);
	 font-size: 40px;
 }
 .imgg{
	 position: absolute;
	 width: 200px;
	 height: 200px;
	 top: 0;
	 left: 0;
	 opacity: 0;
	 cursor: pointer;
 }
 
 #sub-domain-img{
   position: absolute;
	 width: 202px;
	 height: 200px;
	 top: 0;
	 z-index: -1;
 }
 
 .custom-file-label
 {
	 border: unset;
 }
 
 .intervention-form .custom-file-label i 
 {
	 float: right;
 }
 .custom-file-label::after
 {
	 display: none;
 }

 /* Question */
 .accordion {
	 margin-top: 6px; /* Add space above accordion */
 }
 .accordion .card {
	 border-radius: 0;
	 border-width: 1px 0 1px 0;
 }
 .accordion .card:first-child {
	 border-top: none;
 }
 .accordion .card .card-header {
	 background: #fff;
	 padding-top: 4px;
	 padding-bottom: 4px;
	 padding-right: unset;
	 padding-left: unset;
	 border-radius: 0;
	 margin-bottom: -2px;
	 /* font-family: "Roboto", sans-serif; */
 }
 .accordion .card-header:hover {
	 background: #f8f8f8;
 }
 .accordion .card-header h2 a
 {
	 font-size: 12px !important;
 }
 .accordion .card-header h2 span {
	 float: left;
	 margin-top: 10px;
 }
 .accordion .card-header .btn {
	 font-size: 1.04rem;
	 font-weight: 500;
	 width: 100%;
	 text-align: left;
	 position: relative;
	 top: -2px;
 }
 .accordion .card-header i {
	 float: right;
	 font-size: 1.3rem;
	 font-weight: bold;
	 position: relative;
	 top: 5px;
 }
 .accordion .card-header button:hover {
	 color: #23384e;
 }
 .accordion .card-body {
 
	 background: #eaeaea;
	 color: #595959;
	 font-size: 12px;
 
	 background: #ffffff;
	 /* color: #595959; */
	 /* font-size: 12px; */
	 box-shadow: 16px 2px 25px 7px rgb(0 0 0 / 10%);
	 border-radius: 24px;
	 border: 0px solid #fff;
	 margin: 15px 10px;
 }
 .accordion .highlight {
	 color: #fff;
	 background: #202d3c !important;
 }
 
 .card
 {
	 border:none;
 }
 
 /* Question */
 
 .content1:focus-visible ,
 .content2:focus-visible
 {
	 outline: none;
 }
 
 .btn-edit-screener {
 background-color: unset; 
   border: none;  
   padding: 2px 6px;  
   font-size: 16px;  
   cursor: pointer;  
 }
 .btn-edit-screener:hover {
	 /* background-color: rgb(235, 236, 240);  */
 }
 
 main
 {
	 height: auto !important;
	 max-height:inherit!important;
 }
 
 @media (min-width: 576px)
 {
 .modal-dialog {
	 max-width: 800px;
	 margin: 1.75rem auto;
 }
 }
 select.domain-select-hide::-ms-expand {
    display: none;
}
select.domain-select-hide {
-webkit-appearance: none;
-moz-appearance: none;
text-indent: 1px;
text-overflow: '';
}