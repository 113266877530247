.rc-md-editor .rc-md-navigation .button-wrap  .button-type-image
{
	display: none;
}
/* .rc-md-editor .rc-md-navigation .button-wrap  .button-type-code-inline,
.rc-md-editor .rc-md-navigation .button-wrap  .button-type-code-block,
.rc-md-editor .rc-md-navigation .button-wrap  .button-type-table,
.rc-md-editor .rc-md-navigation .button-wrap  .button-type-image,
.rc-md-editor .rc-md-navigation .button-wrap  .button-type-quote,
.rc-md-editor .rc-md-navigation .button-wrap  .button-type-wrap
{
	display: none;
} */