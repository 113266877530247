
     .questionnaire-table  {	
		box-shadow: inset 3px 2px 5px #0000002e, inset -4px -4px 5px #ffffff80; 
		border: 1px solid #acbfd5 !important;
	 }
	 .questionnaire-table  tr:hover
	 {
		 background-color: rgb(235, 236, 240); 
	 }
	 
	 .questionnaire-table td, .questionnaire-table th {
		 border: none;
	 }
	 .questionnaire-table > thead td
	 {
		 font-size: 12px;
		 text-transform: capitalize;
	 
	 }
	 
	 .questionnaire-table td
	 {
		 font-size: 14px;
		 text-transform: capitalize;
	 }
	 
	 .custom-file-label
	 {
		 border: unset;
	 }
	 
	 .questionnaire-form .custom-file-label i 
	 {
		 float: right;
	 }
	 .custom-file-label::after
	 {
		 display: none;
	 }	  
	 
	 .btn-edit-questionnaire {
	 background-color: unset; 
	   border: none;  
	   padding: 2px 6px;  
	   font-size: 16px;  
	   cursor: pointer;  
	 }
	 .btn-edit-questionnaire:hover {
		 background-color: rgb(235, 236, 240); 
	 }
	 
	 
	 @media (min-width: 576px)
	 {
	 .modal-dialog {
		 /* max-width: 800px; */
		 margin: 1.75rem auto;
	 }
	 }
	 .option-panel img{
		 width: 20%;
		 object-fit: contain;
	 }