.view input,
select,
option {
  border: 0;
  background-color: unset !important;
  outline: none !important;
}

.form-control{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.view select {
  appearance: none;
}

.upload-profile{
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
}
.upload-profile img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.upload-profile i{
	position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}