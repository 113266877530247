.custom-accordion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  flex-direction: row;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 2s ease-in-out;
  font-size: 14px;
  font-weight: 600;
  color: #6b778c;
  text-transform: uppercase;
  -webkit-box-shadow: 0 0 10px #fff;
  box-shadow: 0 0 10px #00000020;
  //   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  .fa-chevron-down {
    transform: rotateZ(0deg);
    transition: all 0.3s ease-in-out;
  }
  .accordion-menu {
    width: 2%;
    text-align: center;
    // background-color: red;
    cursor: pointer;
    color: transparent;
  }
}
.custom-accordion:focus-within {
  .accordion-menu {
    color: #6b778c !important;
  }
}
.accordion-menu:focus-within {
  color: #6b778c !important;
}
.custom-accordion:hover {
  .accordion-menu {
    color: #6b778c !important;
  }
}
.custom-accordion .accordion-header {
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 10px 0px 10px 10px;
}

.custom-accordion.active {
  border-bottom: none;
  color: #172b4d;
  .fa-chevron-down {
    transform: rotateZ(180deg);
    transition: all 0.3s ease-in-out;
  }
}
.accordion-menu-header {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.45455;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  padding: 2px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  color: #6b778c !important;
}

.accordion-menu-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #6b778c;
  line-height: 1.2;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #172b4d;
    color: #fff;
  }
}
.option-table {
  .accordion-menu {
    text-align: center;
    // background-color: red;
    cursor: pointer;
    color: transparent;
  }
}
.option-table:focus-within {
  .accordion-menu {
    color: #6b778c !important;
  }
}
.option-table:focus-within {
  color: #6b778c !important;
}
.option-table:hover {
  .accordion-menu {
    color: #6b778c !important;
  }
}
.table-min-h {
  min-height: 100vh;
}
td .good {
  background-color: #cfc;
  color: #064;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
}
td .bad {
  background-color: #fdd;
  border-radius: 4px;
  color: #bf2600;
  padding: 3px;
  cursor: pointer;
}

.good {
  background-color: #cfc;
  color: #064;
  border-radius: 4px;
  padding: 3px;
  cursor: pointer;
}
.bad {
  background-color: #fdd;
  border-radius: 4px;
  color: #bf2600;
  padding: 3px;
  cursor: pointer;
}
