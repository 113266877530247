
.form-control
{
    color: #172b4d;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    background-color: #f4f5f7;
    border: 1px solid #dfe1e6;
    padding: 4px 7px 5px;
    height: 35px;
}

#addInterventionPlan label
{
    color: #5e6c84;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    float: none;
    margin: 0 16px 0 0;
    display: block;
    text-align: left;
    width: auto;
    padding: unset;
}

#addInterventionPlan input[type=text]:focus,
#addInterventionPlan input[type=radio]:focus,
#addInterventionPlan textarea:focus,
#addInterventionPlan select:focus

{
    background-color: #fff;
    border: 1px solid #4c9aff;
    outline: 0;
    box-shadow: 0 0 0 1px #4c9aff;
}


.table-bordered td, .table-bordered th {
    border: none;
}
.table-bordered > thead td
{
    font-size: 12px;
    text-transform: capitalize;

}

.table-bordered td
{
    font-size: 14px;
    text-transform: capitalize;

}

.table-bordered td a:hover
{
    text-decoration: none;
}

@media (min-width: 576px)
{
.modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
}
}