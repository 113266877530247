.modal-header .close {
    padding: 1rem;
    margin: unset !important;
	position: absolute;
	left: .5rem;
	top: .2rem;
}
button:focus {
	outline: none !important;
}
.modal-header
{
    border: unset !important;
	position: relative !important;
	padding: 1rem !important;
	align-items: center !important;
	justify-content: center !important;
}
.btn-outline-success
{
    border: unset !important;
}
.btn-outline-success:not(:disabled):not(.disabled):active
{
    background-color: unset !important;
}
.btn-add-role
{
    border: 1px solid #263a5be6 !important;
    box-shadow: unset !important;
    background: #fff;
    color: #263a5be6;
    padding: 2px 5px;
}
.btn-add-role:focus
{
    border: 1px solid #263a5be6 !important;
}

@media (max-width: 767px) {
	.modal-fullscreen-xs-down {
	  padding: 0 !important;
	}
	.modal-fullscreen-xs-down .modal-dialog {
	  width: 100%;
	  min-width: 100%;
	  height: 100%;
	  margin: 0;
	  padding: 0;
	}
	.modal-fullscreen-xs-down .modal-content {
	  height: auto;
	  min-height: 100%;
	  border: 0 none;
	  border-radius: 0;
	}
  }
  @media (max-width: 991px) {
	.modal-fullscreen-sm-down {
	  padding: 0 !important;
	}
	.modal-fullscreen-sm-down .modal-dialog {
	  width: 100%;
	  min-width: 100%;
	  height: 100%;
	  margin: 0;
	  padding: 0;
	}
	.modal-fullscreen-sm-down .modal-content {
	  height: auto;
	  min-height: 100%;
	  border: 0 none;
	  border-radius: 0;
	}
  }
  @media (max-width: 1199px) {
	.modal-fullscreen-md-down {
	  padding: 0 !important;
	}
	.modal-fullscreen-md-down .modal-dialog {
	  width: 100%;
	  min-width: 100%;
	  height: 100%;
	  margin: 0;
	  padding: 0;
	}
	.modal-fullscreen-md-down .modal-content {
	  height: auto;
	  min-height: 100%;
	  border: 0 none;
	  border-radius: 0;
	}
  }
  .modal-fullscreen {
	padding: 0 !important;
  }
  .modal-fullscreen .modal-dialog {
	width: 100%;
	min-width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
  }
  .modal-fullscreen .modal-content {
	height: auto;
	min-height: 100%;
	border: 0 none;
	border-radius: 0;
  }