
 
 
 .custom-file-label
 {
	 border: unset;
 }
 
 .intervention-form .custom-file-label i 
 {
	 float: right;
 }
 .custom-file-label::after
 {
	 display: none;
 }
  
 
 @media (min-width: 576px)
 {
 .modal-dialog {
	 /* max-width: 800px; */
	 margin: 1.75rem auto;
 }
 }
 
 .float-top-right{
	position: absolute;
	right: 1rem;
	top: 1rem;
 }