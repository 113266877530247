@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,800;1,900&display=swap");

* {
	font-family: "Nunito", sans-serif;
}

@keyframes swing {
	0% {
		transform: rotate(0deg);
	}

	10% {
		transform: rotate(10deg);
	}

	30% {
		transform: rotate(0deg);
	}

	40% {
		transform: rotate(-10deg);
	}

	50% {
		transform: rotate(0deg);
	}

	60% {
		transform: rotate(5deg);
	}

	70% {
		transform: rotate(0deg);
	}

	80% {
		transform: rotate(-5deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

@keyframes sonar {
	0% {
		transform: scale(0.9);
		opacity: 1;
	}

	100% {
		transform: scale(2);
		opacity: 0;
	}
}

body {
	font-size: 0.9rem;
	overflow: hidden !important;
}

.navbar {
	-webkit-box-shadow: 0 8px 6px -6px #999;
	-moz-box-shadow: 0 8px 6px -6px #999;
	box-shadow: 0 8px 6px -6px #999;

	/* the rest of your styling */
}

.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand>a,
.sidebar-wrapper .sidebar-dropdown>a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
	height: 92vh !important;
	overflow: auto !important;
	margin-right: 0px !important;
	width: 100% !important;
}

.page-wrapper .theme {
	width: 40px;
	height: 40px;
	display: inline-block;
	border-radius: 4px;
	margin: 2px;
}

.page-wrapper .theme.chiller-theme {
	background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
	left: 0px;
}

@media screen and (min-width: 768px) {
	.page-wrapper.toggled .page-content {
		padding-left: 270px;
	}
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
	display: inline-block;
	width: 100%;
	padding-left: 0px;
	padding-top: 20px;
}

.page-wrapper .page-content>div {
	padding: 20px 40px;
}

.page-title {
	text-transform: capitalize;
	font-size: 1.71429em;
	font-style: inherit;
	color: #172B4D;
	font-weight: 500;
	letter-spacing: -0.01em;
	margin-top: 0px;
	line-height: 32px;
	outline: none;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
	width: 5px;
	height: 7px;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
}

::-webkit-scrollbar-thumb {
	background: #525965;
	border: 0px none #ffffff;
	border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
	background: #525965;
}

::-webkit-scrollbar-thumb:active {
	background: #525965;
}

::-webkit-scrollbar-track {
	background: transparent;
	border: 0px none #ffffff;
	border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
	background: transparent;
}

::-webkit-scrollbar-track:active {
	background: transparent;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
	background: #ededed;
	border-right: 2px solid #a1a1a1;
}

.chiller-theme .sidebar-wrapper .sidebar-header,
.chiller-theme .sidebar-wrapper .sidebar-search,
.chiller-theme .sidebar-wrapper .sidebar-menu {
	/* border-top: 1px solid #3a3f48; */
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
	border-color: transparent;
	box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
.chiller-theme .sidebar-wrapper .sidebar-brand>a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer>a {
	color: #818896;
	font-size: 14px;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
.chiller-theme .sidebar-wrapper .sidebar-header .user-info,
.chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
.chiller-theme .sidebar-footer>a:hover i {
	color: #263a5be6;
}


.page-wrapper.chiller-theme.toggled #close-sidebar {
	color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
	color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
	color: #16c7ff;
	text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
	/* background: #3a3f48; */
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
	color: #6c7b88;
}

.chiller-theme .sidebar-footer {
	background: #3a3f48;
	box-shadow: 0px -1px 5px #282c33;
	border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer>a:first-child {
	border-left: none;
}

.chiller-theme .sidebar-footer>a:last-child {
	border-right: none;
}

.user-role {
	color: rgb(94, 108, 132);
	font-size: 12px;
}

.sticky-header {
	position: sticky;
	top: 0;
}

.table-borderless td,
.borderless th {
	border: none;
}

.table thead tr {
	border-bottom: 1px solid #acbfd5;
}

.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.logo img {
	width: 120px;
}

.logo span {
	margin: auto 0 auto auto;
}

.user-name {
	font-size: 15px;
	color: #010101;
}

.user-role {
	color: rgb(94, 108, 132);
	font-size: 12px;
}

.table td,
.table th {
	border-top: none !important;
	/* font-size: 12px;
		text-transform: capitalize; */
}

.table thead td {
	font-size: 14px;
	text-transform: capitalize;
	font-weight: 700;
	color: #42526E !important;
}

.table td {
	font-size: 14px;
	text-transform: initial;
}

.table-bordered td,
.table-bordered th {
	/* border: none !important; */
}

.table>thead {
	font-size: 12px;
}

.bg-white {
	font-size: 14px;
}

.btn-toggle.btn {
	font-size: 14px;
}

/* .btn {
	padding: 2px 0.75rem;
} */
.form-control:focus {
	outline: 0 !important;
	border-color: initial;
	box-shadow: none;
}

input:disabled.form-control {
	color: #6c757d !important;
	opacity: 0.7;
}

textarea:disabled.form-control {
	color: #6c757d !important;
	opacity: 0.7;
}

.search {
	-webkit-box-align: center;
	align-items: center;
	background-color: #FAFBFC;
	border-color: #DFE1E6;
	color: #091E42;
	cursor: text;
	border-radius: 3px;
	border-width: 2px;
	border-style: solid;
	box-sizing: border-box;
	display: flex;
	flex: 1 1 100%;
	font-size: 14px;
	-webkit-box-pack: justify;
	justify-content: space-between;
	overflow: hidden;
	transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
	overflow-wrap: break-word;
	vertical-align: top;
	pointer-events: auto;
	position: relative;
}

.search:hover {
	background-color: #DFE1E6;
}

.search input:not([type=checkbox]):not([type=radio]),
select,
textarea {
	outline: 0;
	border-bottom-right-radius: 3.01px;
}

.form-group select {
	font-size: 1rem !important;
}

.search input {
	padding: 4px 6px;
	height: 2.4em;
	background-color: transparent;
	border: 0px;
	box-sizing: border-box;
	color: inherit;
	cursor: inherit;
	font-size: 14px;
	min-width: 0px;
	outline: none;
	width: 100%;
	line-height: 1.42857;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.search input:focus {
	box-shadow: none;
}

.search:focus-within {
	background-color: #FAFBFC !important;
	/* border: 2px solid blue; */
}

.search .search-btn {
	/* position: absolute;
  top: 20px;
  left: 16px; */
	position: absolute;
	top: 5px;
	right: 13px;
	height: 5px;
	width: 6px;
}

.search button {

	color: rgb(94, 108, 132);
	/* margin: 0px 8px 0px 8px; */
	padding: 8px;
	display: flex;
	cursor: pointer;
	border: none;
	background: transparent;
	font-size: 14px;
}

.btn-outline-success {
	background-color: #263a5be6 !important;
	color: #fff !important;
	border-color: #263a5be6 !important;
}

.btn-outline-success:hover {
	color: #263a5be6 !important;
	background-color: #fff !important;
	border-color: #263a5be6 !important;
}

.btn-outline-success:not(:disabled):not(.disabled):active {
	color: #263a5be6 !important;
	background-color: #fff !important;
	border-color: #263a5be6 !important;
}

@media (min-width: 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

/* Fields */

.form-control {

	border: 1px solid rgb(223, 225, 230);

}


.form-control {
	color: #172b4d;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	background-color: #f4f5f7;
	border: 1px solid #dfe1e6;
	padding: 4px 7px 5px;
}

.react-datepicker__input-time-container {
	background-color: #f0f0f0 !important;
	margin: 0px !important;
	padding: 5px 0 10px 15px !important;
}

.react-datepicker-time__caption {
	color: #263a5b;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	float: none;
	margin: 0 16px 0 0;
	display: block;
}

.react-datepicker {
	color: #263a5b !important;
}

input[type=time].react-datepicker-time__input {
	color: #172b4d;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	line-height: 20px;
	background-color: #f4f5f7;
	border: 1px solid #dfe1e6;
	padding: 4px 7px 5px;
}

.form-group label {
	color: #5e6c84;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	float: none;
	margin: 0 16px 0 0;
	display: block;
	text-align: left;
	width: auto;
	padding: unset;
	width: 30%;
}

.form-group input[type=time]:focus.react-datepicker-time__input,
.form-group input[type=text]:focus,
.form-group textarea:focus,
.form-group select:focus {
	background-color: #fff;
	border: 1px solid #4c9aff;
	outline: 0;
	box-shadow: 0 0 0 1px #4c9aff;
}

.form-control {
	height: calc(2.25rem + 2px);
}

.form-group textarea {
	min-height: 115px;
	max-height: 115px;
}

/* Fields */

.h-85p {
	height: 90px !important;
}

/* custom Upload */

.flex {
	display: flex;
}

.wrap {
	flex-wrap: wrap;
}

.upload__list {
	position: relative;
	padding: 5px 15px;
	border: 1px solid #ececec;
	overflow: hidden !important;
}

.upload__list p {
	/* white-space: nowrap; */
	/* text-overflow: ellipsis; */
}

.upload__info {
	height: 80px;
	border-bottom: 1px solid #ececec;
	flex-direction: row;
	align-items: center;
}

.upload__info:last-child {
	border: none;
}

.upload__img {
	width: 80px;
	height: 80%;
	width: 50px;
	height: 30px;
	margin-right: 10px;
	object-fit: cover;
	-o-object-fit: cover;
}

.upload__img1 {
	width: 50px;
	height: 30px;
	margin-right: 10px;
	object-fit: cover;
	-o-object-fit: cover;
}

.upload__progress {
	background: #ddd;
	height: 7px;
	width: 100%;
	margin-top: 10px;
}

.upload__bar {

	background-image: linear-gradient(45deg,
			rgba(255, 255, 255, 0.15) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, 0.15) 50%,
			rgba(255, 255, 255, 0.15) 75%,
			transparent 75%,
			transparent);

	background-size: 1rem 1rem;
	width: 50%;
	height: 100%;
	background-color: #3695db;
	transition: all 0.3s ease;
}

.w-100 {
	width: 100%;
}

.upload__name {
	margin: 7px 5px 0px 0px;
}

.upload__size {
	margin: 7px 5px 0px 5px;
}

.upload__long {
	margin: 7px 0px 0px 5px;
	font-weight: bold;
}

.upload__name,
.upload__size,
.upload__long {
	font-size: 12px;
}

.upload__btn {
	text-decoration: none;
	color: #fff;
	background: #3b4d6b;
	padding: 7px 15px;
	border-radius: 50px;
	font-size: 12px;
}

.upload__delete {
	margin-left: auto;
}

.upload__input {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}

.upload__drop {
	position: relative;
	/* border: 2px dotted #3695db; */
	padding: 15px 10px;
	overflow: hidden;
	margin-bottom: 10px;
	cursor: pointer;
}

.upload__drop span {
	font-size: 12px;
}

/* Custom uploads */


/* font awesome icon */

.fas.fa-edit {
	color: #3a4c6b;
}

.fas.fa-times {
	color: #ff7d7d;
}

.fas.fa-save {
	color: #3a4c6b;

}

/* Font awesome icon */


/* Modal */

.modal-header {
	padding: unset !important;
	border-bottom: unset !important;
}

/* .modal-header .close {
  padding: 1rem;
  margin: -1rem 0rem -1rem auto;
} */

@media (max-width: 767px) {
	.modal-fullscreen-xs-down {
		padding: 0 !important;
	}

	.modal-fullscreen-xs-down .modal-dialog {
		width: 100%;
		min-width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.modal-fullscreen-xs-down .modal-content {
		height: auto;
		min-height: 100%;
		border: 0 none;
		border-radius: 0;
	}
}

@media (max-width: 991px) {
	.modal-fullscreen-sm-down {
		padding: 0 !important;
	}

	.modal-fullscreen-sm-down .modal-dialog {
		width: 100%;
		min-width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.modal-fullscreen-sm-down .modal-content {
		height: auto;
		min-height: 100%;
		border: 0 none;
		border-radius: 0;
	}
}

@media (max-width: 1199px) {
	.modal-fullscreen-md-down {
		padding: 0 !important;
	}

	.modal-fullscreen-md-down .modal-dialog {
		width: 100%;
		min-width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
	}

	.modal-fullscreen-md-down .modal-content {
		height: auto;
		min-height: 100%;
		border: 0 none;
		border-radius: 0;
	}
}

.modal-fullscreen {
	padding: 0 !important;
}

.modal-fullscreen .modal-dialog {
	width: 100%;
	min-width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

.modal-fullscreen .modal-content {
	height: auto;
	min-height: 100%;
	border: 0 none;
	border-radius: 0;
}

/* Modal */

.imgMask {
	position: relative;
	width: 200px;
	height: 200px;
	border: 1px solid black;
	cursor: pointer;
}

.imgMask-content {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 40px;
}

.imgg {
	position: absolute;
	width: 200px;
	height: 200px;
	top: 0;
	left: 0;
	opacity: 0;
	cursor: pointer;
}

.page-item.active .page-link {
	z-index: 1;
	color: #fff !important;
	background-color: #263a5be6 !important;
	border-color: #263a5be6 !important;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.5rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #263a5be6 !important;
	background-color: #fff;
	border: 1px solid #999 !important;
}

.rc-slider-track {
	background-color: #3b4d6b !important;
}

.rc-slider-handle {
	border: solid 2px #3b4d6b !important;
}

.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
	border-color: #3b4d6b !important;
	box-shadow: 0 0 0 5px #505763 !important;
}

.rc-slider-handle-click-focused:focus {
	border-color: #3b4d6b !important;
}

.rc-slider-handle:focus {
	outline: none;
	box-shadow: 0 0 0 3px #505763 !important;
}

.rc-slider-rail {
	background-color: #bdbdbd !important;
}

.custom-card {
	background-color: #fcfcfc;
	border-radius: 10px;
	padding: 1rem;
	box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.border-gray {
	border: 1px solid #dee2e6 !important;
}

.license-period {
	margin-bottom: 1rem;
}

.month {
	margin: 3px 3px;
}

.license-period label {
	color: #5e6c84;
	font-size: 12px;
	font-weight: 600;
}

.month label {
	color: #5e6c84 !important;
	font-size: 12px !important;
	position: relative !important;
	font-weight: 600 !important;
	top: -2px !important;
}

.clear-button {
	position: relative;
	left: 50px;
}

.start-stop label {
	margin: 7px 7px;
}

.startDate {
	margin: 7px 7px;
}

.endDate {
	margin: 7px 7px;
}

.listcheckox {
	position: relative !important;
	top: 3px !important;
}

.border-end {
	border: solid;
	border-width: 0px 2px 0px 0px;
}

.section-border {
	border: solid 1px #091E42a0;
	position: relative;
	padding-top: 1.3rem;
}

.section-border .section-label {
	position: absolute;
	top: 0px;
	transform: translateY(-50%);
	left: 15px;
	z-index: 100;
	background-color: #fff;
	display: flex;
	width: auto !important;
}

/* .section-border input[type='text']:focus,
input[type='password']:focus,
input[type='number']:focus,
textarea:focus {
	border: solid 1px #091E42 !important;
} */

.banner-preview-modal .modal-content {
	background-color: #f0f0f0;
}

.banner-preview-modal .container {
	width: 90% !important;
}

.banner-preview-modal .item {
	width: 100% !important;
	height: 79vh !important;
	aspect-ratio: 13/6;
	border-radius: 20px;
	overflow: hidden;
}


.play-button-section {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.play-button-section img {
	width: 100px !important;
	cursor: pointer;
	object-fit: contain !important;
	filter: drop-shadow(2px 4px 6px black);
}

.play-button-section img:hover {
	filter: drop-shadow(2px 4px 6px black) sepia(1);
}

.z-index-1000 {
	z-index: 1000;
}

/* .banner-preview-modal {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	overflow: hidden;
	z-index: 1;
} */


.banner-preview-modal video,
.banner-preview-modal div video,
.banner-preview-modal img {
	object-fit: cover !important;
	width: 100%;
	height: auto;
}

.banner-shadow {
	box-shadow: -3.564px -1.816px 16px 0px rgb(255 255 255 / 0%), 3.564px 1.816px 16px 0px rgb(126 126 126 / 48%);
	border-radius: 20px;
	overflow: hidden;
}


.banner-text-container {
	position: absolute;
	width: 40%;
	height: 100%;
	left: 0px;
	top: 0px;
	overflow: hidden;
	z-index: 5;
	padding-left: 4vw;
	display: flex;
	align-items: start;
	flex-direction: column;
	justify-content: center;
}

.banner-text-container .text-view {
	color: #fff;
}

.banner-text-container .text-view p {
	/* font-family: 'NexaRegular'; */
	font-size: 1.5vw !important;
	line-height: 1.2;
	text-shadow: rgb(0 0 0 / 20%) 0px 2px 2px;
}

.banner-text-container .text-view b,
.banner-text-container .text-view strong,
.banner-text-container .text-view h1,
.banner-text-container .text-view h2,
.banner-text-container .text-view h3,
.banner-text-container .text-view h4,
.banner-text-container .text-view h5,
.banner-text-container .text-view h6 {
	font-size: 1.89rem !important;
	line-height: 1.2;
	/* font-family: 'NexaFreeBoldwebfont'; */
}