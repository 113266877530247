.editor-view {
  overflow-y: auto;
  height: 87vh;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.component-container {
  height: 81.5vh !important;
}
.editor-view .isDragged {
  border: 2px dashed #000;
}
.pb-60 {
  padding-bottom: 60px !important;
}

.card-bg {
  background-color: #fff;
}

.border-dashed {
  border: 2px dashed #6c757da0;
}

section .tool-tip {
  position: absolute;
  display: none;
}

.editor-component section {
  position: relative;
  .tool-tip {
    display: flex !important;
    position: absolute;
    opacity: 0;
    flex-direction: row;
    box-shadow: inset 3px 2px 5px #0000002e, inset -4px -4px 5px #ffffff80 !important;
    background-color: #cafc72;
    top: 0px;
    right: 0px;
    border-radius: 10px;
    button {
      padding: 10px 10px 10px 10px;
      background: #ffffff00;
      color: #fff;
      border: none;
      cursor: pointer;
      .fas.fa-edit {
        color: #fff;
      }
    }
    button:first-child {
      padding-right: 5px;
    }
    button:last-child {
      padding-left: 5px;
    }
  }
  &:hover .tool-tip {
    opacity: 1;
    z-index: 20000;
  }
}

.play-button {
  width: 80px !important;
  object-fit: contain !important;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  filter: drop-shadow(2px 4px 6px black) sepia(0);
}

.play-button:hover {
  filter: drop-shadow(2px 4px 6px black) sepia(1);
}
.header-right-btn {
  display: flex;
  position: absolute;
  right: 0px;
  height: 100%;
  align-items: center;
  justify-content: end;
}

.header-left-btn {
  display: flex;
  position: absolute;
  left: 0px;
  height: 100%;
  align-items: center;
  justify-content: start;
}
