@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
}
@font-face {
  font-family: "NexaFreeBoldwebfont";
  src: url("../../assets/fonts/NexaFreeBoldwebfont.eot");
  src: local("NexaFreeBoldwebfont"),
    url("../../assets/fonts/NexaFreeBoldwebfont.woff") format("woff"),
    url("../../assets/fonts/NexaFreeBoldwebfont.ttf") format("truetype");
}

body {
  font-family: "Nunito", sans-serif;
  background-color: #f0f0f0;
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #12284c;
}

.assessment-content-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 3.564px 1.816px 8px 0px rgba(95, 157, 231, 0.48),
    inset -3.564px -1.816px 8px 0px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.assessment-content-box::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

.assessment-content-box::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #09b2ff;
}

.detail-view-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset -3.564px -1.816px 8px 0px rgba(255, 255, 255, 0.004),
    inset 3.564px 1.816px 8px 0px rgba(126, 126, 126, 0.48);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.detail-view-section::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.detail-view-section::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(18, 40, 76);
}

.slim-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset -3.564px -1.816px 8px 0px rgba(255, 255, 255, 0.004),
    inset 3.564px 1.816px 8px 0px rgba(126, 126, 126, 0.48);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.slim-2::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f5f5f5;
}

.slim-2::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(18, 40, 76);
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 90% !important; /*don't change that value - layout fixed width */
  }
}

.theme-bg {
  background-color: #f0f0f0;
}

/* title section  */
.main-section-title {
  text-transform: uppercase;
  font-size: 25px;
  color: #000;
  font-weight: 700;
  margin-bottom: 4px;
}
.main-section-title-sm {
  text-transform: uppercase;
  font-size: 20px;
  color: #000;
  font-weight: 600;
}
.main-section-content {
  font-size: 20px;
  color: #000;
}
.main-section-content-sm {
  font-size: 14px;
  color: #000;
  font-weight: 400;
}
.main-section-content-sm-2{
  font-size: 18px;
  color: #000;
  font-weight: 400;
}
.text-transform-initial {
  text-transform: initial !important;
}

.wit-section-top {
  padding-top: 60px;
}
.wit-section-bottom {
  padding-bottom: 60px;
}

/* owl carousel nav arrow */
/* .owl-nav-arrow.nav-true .owl-nav.disabled{
display: block !important;
  } */


.text-align-justify{
  text-align: justify !important;
}
.scroll-top:hover i {
  animation: fadeOutUp 1s ease infinite;
}
.cursor-pointer {
  cursor: pointer !important;
}

/* sm slider card  */
.card.workout-card {
  width: 100%;
  position: relative;
  height: 18em;
  perspective: 150em;
  border: unset !important;
  margin-right: 20px;
  border-width: 2px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-radius: 20px !important;
  background-color: transparent;
  box-shadow: -3.564px -1.816px 16px 0px rgba(255, 255, 255, 0.004), 3.564px 1.816px 16px 0px rgba(126, 126, 126, 0.48);
  flex-direction: unset !important;


}

.card__side {
  width: 100%;
  backface-visibility: hidden;
  transition: all 0.6s ease;
border: none !important;
  border-radius: 20px;

}

.card__side--front {
  background-color: #fff;
  border-width: 2px;
  /* border-color: rgb(235, 235, 235); */
  /* border-style: solid; */
  border-radius: 29px;
  background-color: rgb(240, 240, 240);
  /* box-shadow: -3.564px -1.816px 16px 0px rgba(255, 255, 255, 0.004), 3.564px 1.816px 16px 0px rgba(126, 126, 126, 0.48); */

}

.card__side--back {
  color: #fff;
  transform: rotateY(180deg);

}

.card__side--back-1 {
  background-color: #1a2e50;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.card__side--back-2 {
  background-color: #1a2e50;
  border-width: 2px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-radius: 29px;
  /* background-color: rgb(240, 240, 240); */
  box-shadow: -3.564px -1.816px 16px 0px rgba(255, 255, 255, 0.004), 3.564px 1.816px 16px 0px rgba(126, 126, 126, 0.48);


}

.card__side--back-3 {
  background-color: #1a2e50;
  border-width: 2px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-radius: 29px;
  /* background-color: rgb(240, 240, 240); */
  box-shadow: -3.564px -1.816px 16px 0px rgba(255, 255, 255, 0.004), 3.564px 1.816px 16px 0px rgba(126, 126, 126, 0.48);


}

.card:hover .card__side--front {
  transform: rotateY(-180deg);
}

.card:hover .card__side--back {
  transform: rotate(0);
}

.card__description {
  text-transform: capitalize;
  font-size: 18px;
  text-align: left;
  /* margin-left: 10px; */
  font-weight: 600;
  /* margin-top: 20px; */
  /* margin-bottom: 10px; */
  /* margin: 10px 10%; */
  margin: 5px 10px;
}

.workout-section .card__description {
  font-weight: 500;
}

.card__description img {
  width: 100%;
  height: 400px;
  fill: #fff;
}

.card .card__side--front img {

  width: 100%;
  /* height: 10vw; */
  object-fit: cover;
}

.card.workout-card .card__side--front img
     {
    width: 100%;
    height: 220px;
    max-height: 200px;
    object-fit: cover;
  }


.card__description h5 {
  font-size: 12px;
  text-align: left;
  /* margin: 10px; */
  padding: 5px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 20px 0px 10px 0px;
}

.card__description p {
  font-size: 12px;
  text-align: left;
  /* margin: 10px; */
  padding: 2px 5px;
  text-transform: initial;
  margin: 5px 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.card__description p .card__description span {
  font-size: 12px;
  text-align: left;
  text-transform: capitalize;
  margin-right: 10px;
}

.card__description .workout-info {
  margin: 5px;
  padding: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.card__description .workout-info span{
  width: 50%;
  text-align: left;
}
.card__description .workout-info .fa-star,
.fa-star-half-o {
  font-size: 8px;
}
/* .card.workout-card{
  background: transparent !important;
  border-radius: 20px;
  border: none;
} */

.mobile-field{
  width: 100%;
}

.elevator-border-left{
  position: fixed;
  left: 0;
  height: 100%;
  z-index: 4;
}
.elevator-border-right{
  position: fixed;
    right: 0;
    height: 100%;
    z-index: 4;
}
.elevator-border-left img, .elevator-border-right img{
height: 100%;
}

.therapy-banner-img{
	position: relative;
  border-radius: 20px;
  height: calc(100vh - 230px);
  overflow: hidden;
  box-shadow: 0px 0px 9px 1px #0000001f;
  z-index:-2;
}
.therapy-banner-img > img,
.therapy-banner-img > video{
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.want-btn-bg{
  background: #11274e;
  color: #fff;
  border: none;
  padding: 8px 48px;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 50px;
  margin: 6px 0px 42px 0px;
  box-shadow: 5px 5px 5px 0px #7e7e7e52;
}
.pdf-link img{
  width: 20px;
  margin-right: 8px;
}
.align-items-center{
  align-items: center;
}
.new-recommended-item .suggest-read-more{ 
left: 0px !important;
width: 100%;
text-align: center;
}
.new-recommended-item .suggest-read-more a{
  font-size: 12px !important;
  padding: 6px 18px !important;
}
.new-recommended-item{
  height: 290px !important;
}
.new-recommended-item .insight-reads-content p{
  -webkit-line-clamp: 2 !important;
}
.recommended-item-play{
  position: absolute;
    top: 0;
    z-index: 4;
    width: 100%;
    height: 140px;
    text-align: center;
    display: flex;
    align-items: center;
}
.recommended-item-play img{
  width: 60px !important;
    height: unset !important;
    margin: 0 auto;
    object-fit: unset;
    filter: invert(3) brightness(0.1);
    cursor: pointer;
}
.viewall-btn{
  color: #000;
    padding: 5px 30px;
    border-radius: 50px;
    text-decoration: none !important;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    background-color: rgb(242, 240, 240);
    box-shadow: inset 3px 4px 5px #ebebebfa, inset -4px -4px 5px #f0f0f0, 2px 3px 6px 1px #8f8e8e30, -2px -1px 6px 1px #ffffff;
    border: 1px solid #ebebeb;
    cursor: pointer;
    display: inline-block;
}





.theme-radio label{
font-weight: 700;
}
.theme-radio [type="radio"]:checked,
.theme-radio [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
.theme-radio [type="radio"]:checked + label,
.theme-radio [type="radio"]:not(:checked) + label
{
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}
.theme-radio [type="radio"]:checked + label:before,
.theme-radio [type="radio"]:not(:checked) + label:before {
    content: '';
    width: 20px;
  height: 20px;
  bottom: 0;
  background: #f0f0f0;
  position: absolute;
  right: -25px;
  border-radius: 50px;
  box-shadow: inset 3px 2px 5px #0000002e, inset -4px -4px 5px #ffffff;
}
.theme-radio [type="radio"]:checked + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #09B1FD;
    position: absolute;
    top: 4px;
    right: -22px;
    border-radius: 100%;
}


.complet-module{
  padding: 16px 0px;
    border-top: 1px solid #b7b7b7;
    border-bottom: 1px solid #b7b7b7;
    margin-bottom: 44px;
}


.privacy-banner img,.privacy-banner video{
width: 100%;
/* height: 100%; */
object-fit: cover;
}
.privacy-content{
  color: #1c3153;
  font-size: 16px;
  font-weight: 700;
}
.privacy-list{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  grid-auto-rows: minmax(100px, auto);
}
.privacy-list-tile{
  border-width: 2px;
  border-color: rgb(235, 235, 235);
  border-style: solid;
  border-radius: 20px;
  background-color: rgb(242, 240, 240);
  box-shadow: -3.564px -1.816px 16px 0px rgb(255 255 255 / 0%), 3.564px 1.816px 16px 0px rgb(126 126 126 / 48%);
  width: 100%;
  display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 0px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
  -o-transition: all 0.3s;
	transition: all 0.3s;
}
.privacy-list-tile:hover{
  transform: scale(1.1);
}
.privacy-list-tile img{
  width: 70px;
}
.privacy-list-tile label{
  margin: 0;
    color: #1c3153;
    font-size: 20px;
    font-weight: 700;
    margin-top: 10px;
}
.privacy-list-tile p{
  margin: 0;
    font-size: 12px;
    color: #000;
}

.privacy-modal{
  max-width: 1000px;
 
}
.privacy-modal .modal-content{
  border-radius: 20px;
  box-shadow: 1px 1px 11px #fff;
  border: none;
  background: #F0F0F0;
}
.privacy-modal .privacy-modal-header{
  margin-top: 22px;
}
.privacy-modal .privacy-modal-header label{
  margin: 0;
  font-size: 20px;
  font-weight: 800;
  color: #12274b;
  display: flex;
  align-items: center;
}
.privacy-modal .privacy-modal-header label img{
  width: 22px;
  margin-right: 5px;
}
.privacy-modal .tc-header-close{
  position: absolute;
    right: 0;
}
.privacy-modal-content{
  min-height: 200px;
    max-height: 370px;
    overflow-y: auto;
    margin-top: 22px;
    padding-right: 42px;
}
.privacy-modal-content p{
  font-size: 13px;
    color: #12284c;
    text-align: justify;
}
.privacy-modal-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 10px 0px;
}
.download-privacy p{
  margin: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    color: #000;
    cursor: pointer;
}
.download-privacy p img{
  width: 25px;
  margin-left: 6px;
}
.update-date{
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}
.privacy-modal .modal-body{
  padding: 40px 35px 28px 35px;
}
.privacy-modal-content::-webkit-scrollbar {
  width: 6px;
}
.privacy-modal-content::-webkit-scrollbar-thumb {
  background-color: #0AB1FE;
}


.carousel-type-1{
  height: unset !important;
}
.carousel-main-img{
  height: 220px !important;
}
.carousel-main-title{
  text-transform: uppercase;
  font-size: 24px !important;
  font-weight: 700 !important;
  margin: 0;
}
.carousel-header-title{
  font-size: 22px !important;
  font-weight: 700 !important;
  margin: 0;
  text-align: center;
  padding: 8px;
}
.carousel-description{
  font-size: 14px !important;
  font-weight: 600 !important;
}
.carousel-type-1 .insight-reads-img{
position: relative;
}
.carousel-like{
  position: absolute;
    top: 14px;
    right: 18px;
}
.carousel-like i{
  color: #fff;
  background: linear-gradient(121deg, rgb(16 22 29 / 21%) 0%, rgb(255 255 255) 50%, rgb(255 255 255) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.carousel-like i.active{
  background: linear-gradient(136deg, #FA1768 0%, #FA1768 30%, #FA1768 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.carousel-overview{
  display: flex;
  justify-content: space-between;
  padding: 8px 1rem;
  align-items: center;
}
.carousel-detail ul{
display: flex;
}
.carousel-detail li i{
  background: linear-gradient(136deg, rgb(255 255 255 / 21%) 0%, #d4d4d4 30%, #b7b7b7bd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
.carousel-detail li .fa-heart.active{
  background: linear-gradient(136deg, #FA1768 0%, #FA1768 30%, #FA1768 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.carousel-detail li .fa-calendar.active{
  background: linear-gradient(136deg, #13294A 0%, #13294A 30%, #13294A 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*{Common Banner Starts}*/


.common-banner{
  border-radius: 25px;
    overflow: hidden;
    position: relative;
    height: 480px;
}

@media screen and (max-width: 390px){
  .common-banner{
    height: 200px;
  }
}
.common-video-box{
  margin-top: -100px;
}

.common-game-play-section{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.common-game-play-section img{
    width: 100px;
    cursor: pointer;
}
.common-game-play-section img:hover{
  filter: drop-shadow(2px 4px 6px black) sepia(1);
}

.common-domian-vide-left{
  position: absolute;
    top: 35%;
    left: 7.3%;
    z-index: 2;
}

@media screen and (max-width: 390px)
{
  .common-domian-vide-left{
    position: absolute;
    top: 45%;
    left: 7.3%;
    z-index: 2;
    margin: -81px 0px 0px 0px
  }
  .common-domian-vide-left h1{
    /* font-size: 8px; */
    font-size: 16px !important;
    margin-bottom: 0px!important;
    margin-top: 24px !important;
    margin-left: 10px !important;
  }
  .common-domian-vide-left h2{
    /* font-size: 16px; */
    font-size: 14px;
    margin-top: 20px;
    line-height: 20px;
  }
  #big-video .tour-btn
  {
    margin-top:unset;
    font-size: 12px !important;
  }
  .main-section-content {
    font-size: 16px;
    color: #000;
  }
  .main-section-title {
    text-transform: uppercase;
    font-size: 18px;
  }
  .exercise-list .carousel-indicators li .exercise-list-title label {
    font-size: 15px !important;
    font-weight: 500;
}



}
  
.common-domian-vide-left h1{
color: #fff;
font-size: 36px;

}

.common-domian-vide-left h2{
  color: #fff;
  font-family: 'NexaFreeBoldwebfont';
}

@media(min-width:320px) and (max-width:768) {
  .common-banner {
    top: 45%;
}
}
@media(min-width:1920px) and (max-width:2499px) {
  .common-banner {
    top: 45%;
}

}

@media(min-width:391px) and (max-width:450px) {

.common-domian-vide-left {
  position: absolute;
  top: unset !important;
  left: 7.3%;
  z-index: 2;
}
.main-section-content {
  font-size: 16px;
  color: #000;
}
.common-banner{
  height: unset;
}
.main-section-title {
  text-transform: uppercase;
  font-size: 18px;}

.common-domian-vide-left h1{
  /* font-size: 8px; */
  font-size: 16px !important;
  margin-bottom: 0px!important;
  margin-top: 44px !important;
  margin-left: 10px !important;
}
.common-domian-vide-left h2{
  /* font-size: 16px; */
  font-size: 14px;
  margin-top: 20px;
  line-height: 20px;
}
#big-video .tour-btn
{
  margin-top:unset;
  font-size: 12px !important;
}
.main-section-content {
  font-size: 16px;
  color: #000;
}
.main-section-title {
  text-transform: uppercase;
  font-size: 18px;}

  .exercise-list .carousel-indicators li .exercise-list-title label {
    font-size: 15px !important;
    font-weight: 500;
}


}

@media(min-width:760px) and (max-width:1024px){

  .common-domian-vide-left {
    position: absolute;
    top: 23%;
    left: 7.3%;
    z-index: 2;
}
.common-domian-vide-left h2 {
  color: #fff;
  font-family: 'NexaFreeBoldwebfont';
  font-size: 30px;
}
.common-domian-vide-left h1 {
  color: #fff;
  font-size: 32px;
  margin-bottom: 15px !important;
}
}

@media(min-width:300px) and (max-width:380px)
{
  .goto-page-btn {
    color: #000;
    padding: 5px 2px !important;
  }
}

.video-play-btn{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-play-btn img{
    width: 90px;
}
.video-play-btn img:hover{
    cursor: pointer;
    filter: brightness(9.5) contrast(1);
}
/*{Common Banner Ends}*/

.privacy-banner{
  border-radius: 25px;
    overflow: hidden;
    position: relative;
    /* height: 500px; */
}
.view-package-title{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #11274ea1;
}
.view-package-title h2{
  text-transform: uppercase;
  font-size: 36px;
  color: #fff;
  font-weight: 700;
}
.view-package-title p{
  color: #fff;
    font-weight: 600;
    font-size: 20px;
}

.carousel-media-detail{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 12px;
}
.carousel-media-detail ul{
  display: flex;
    margin: 0;
}
.carousel-media-detail ul li{
  box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.carousel-media-detail ul li img{
  width: 16px !important;
}
.carousel-media-detail .carousel-media-min{
  font-size: 12px;
  font-weight: 700;
}
.carousel-box-shadaow{
  padding: 24px 20px;
  border-radius: 20px;
  box-shadow: 3px 4px 6px 2px #00000021, -3px -2px 6px 2px #ffffff21, inset -1px 0px 6px 2px #e5e5e517;
}
.overall-rate-row{
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;
}
.overall-rate-row .overall-rate-col{
  font-size: 14px;
  font-weight: 600;
}
.overall-rate-col .fa-heart, .overall-rate-col .fa-calendar{
  background: linear-gradient(136deg, rgb(255 255 255 / 21%) 0%, #d4d4d4 30%, #b7b7b7bd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}

.overall-rate-col .fa-heart.active{
  background: linear-gradient(136deg, #FA1768 0%, #FA1768 30%, #FA1768 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.overall-rate-col .fa-calendar.active{
  background: linear-gradient(136deg, #13294A 0%, #13294A 30%, #13294A 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-tag-list{
  padding: 24px 20px;
}
.card-tag-list ul{
  margin: 0;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 10px;
}
.card-tag-list ul li{
  background: #C8CAC8;
    padding: 4px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
}

.aboutcarousel{
  height: 510px;
    overflow: hidden;
    border-radius: 20px;
}
.aboutcarousel .about-img{
  height: 520px;
    object-fit: cover;
}
.aboutcarousel .about-img img{
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.aboutcarousel .item{
  height: 510px;
  position: relative;
}
.aboutcarousel .owl-item::before{
content: "";
background: url("../../assets/Images/Blog/about-path.png");
position: absolute;
    right: -3px;
    top: 0;
    width: 48%;
    height: calc(100% + 6px);
    background-size: 100% 100%;
    z-index: 1;
}
.about-img-content{
  position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    display: flex;
    padding: 12px 36px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}
.about-img-content h2{
  font-size: 50px;
  font-weight: 600;
  text-align: right;
  color: #7cc1d6;
}
.about-img-content p{
  font-size: 30px;
    font-weight: 800;
    text-align: right;
    margin: 0;
    position: relative;
    cursor: pointer;
    padding-top: 3rem;
}
.about-img-content p::before{
  content: "";
  background: url("../../assets/Images/Blog/about-link-bg.png");
  position: absolute;
    bottom: 0;
    right: 0;
    width: calc(100% + 20px);
    height: 20px;
    background-size: 100% 100%;
    z-index: -1;
}

.aboutcarousel .owl-dots{
  position: absolute;
    right: 0;
    bottom: 0;
    width: 30%;
    text-align: right;
    padding: 22px 26px;
}
.aboutcarousel .owl-dots button{
  border-radius: 50%;
  background-color: rgb(255 255 255) !important;
  box-shadow: inset -5px -4px 8px 0px rgb(255 255 255 / 66%), inset 3.564px 1.816px 8px 0px rgb(126 126 126 / 48%);
  width: 20px;
  height: 20px;
  margin-right: 14px;
  position: relative;
}
.aboutcarousel .owl-dots button.active::before{
content: "";
position: absolute;
    width: 12px;
    height: 12px;
    background: #07B4FE;
    border-radius: 100px;
    top: 4px;
    left: 4px;
}

.about-content{
  position: relative;
    height: 640px;
    display: flex;
    align-items: center;
}
.about-content::before{
  content: "";
  background: url("../../assets/Images/Blog/coffee-cup.png");
  position: absolute;
    width: 100%;
    height: 100%;
    background-position: right top;
    background-size: 100%;
    background-repeat: no-repeat;
}

.about-bg-dark{
  background: #e5e6e4;
}

.map-img .workplace-bg{
  width: 100%;
}
.workplace-map{
  position: relative;
}
.workplace-link-1{
  position: absolute;
  width: 240px;
  height: 224px;
  text-align: center;
  top: calc(calc(100vh - 230px) / 2.6);
  left: calc(calc(100vw - 360px) / 4);
}
.workplace-link-2{
  position: absolute;
  width: 270px;
  height: 250px;
  text-align: center;
  top: calc(calc(100vh - 230px) / 3.3);
  left: calc(calc(100vw - 360px) / 2);
}
.workplace-link-3{
  position: absolute;
  width: 200px;
  height: 250px;
  text-align: center;
  top: calc(calc(100vh - 230px) / 3.3);
  right: calc(calc(100vw - 360px) / 5.1);
}
.workplace-link-4{
  position: absolute;
  width: 215px;
  height: 200px;
  text-align: center;
  bottom: calc(calc(100vh - 230px) / 2.5);
  right: calc(calc(100vw - 360px) / 2);
}
.workplace-link-title{
  font-size: calc(14px + 6 * ((100vw - 320px) / 680));
  color: #57b4b7;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 3px;
}
.workplace-link-content{
  color: #000;
    font-size: calc(1.8px + 6 * ((100vw - 320px) / 680));
    font-weight: 800;
    margin-bottom: 3px;
}
.workplace-info{
  cursor: pointer;
}
.workplace-info img{
  width: 8px;
}
.workplace-icon img{
  width: 70px;
  padding: 10px;
}

.tiles-container{
  display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 35px;
}
.tiles-container .tiles-col{
  height: 340px;
  width: 100%;
  border-radius: 20px;
  background-color: rgb(201 252 113);
  box-shadow: inset -1px 2px 2px #ffffffcf, inset 0px 0px 0px #ffffff00, 2px 3px 6px 1px #8f8e8e30, -2px -1px 6px 1px #ffffff;
  /* border: 1px solid #ebebeb; */
  overflow: hidden;
}
.tiles-col-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tiles-col-content-1{
  position: relative;
  background: #C9FC71;
    padding: 24px 24px;
    overflow: unset !important;
}

.tiles-col-content-1::before{
  content: "";
  width: 70px;
    height: 26px;
    background: #c9fc71;
    box-shadow: inset -1px 2px 2px #ffffffcf, inset 0px 0px 0px #ffffff00, 2px 3px 6px 1px #8f8e8e30, 3px 1px 6px 1px #ffffff;
    position: absolute;
    right: -68px;
    bottom: 30px;
    z-index: 1;
    border-radius: 0px 20px 20px 0px;
}


.tiles-col-content-2{
  position: relative;
  background: #A4E0A2 !important;
    padding: 24px 24px;
    overflow: unset !important;
}
.tiles-col-content-2::before{
  content: "";
  width: 70px;
    height: 26px;
    background: #A4E0A2;
    box-shadow: inset -1px 2px 2px #ffffffcf, inset 0px 0px 0px #ffffff00, 2px 3px 6px 1px #8f8e8e30, 3px 1px 6px 1px #ffffff;
    position: absolute;
    right: -68px;
    bottom: 30px;
    z-index: 1;
    border-radius: 0px 20px 20px 0px;
}

.tiles-col-content-3{
  position: relative;
  background: #7CC1D6 !important;
    padding: 24px 24px;
    overflow: unset !important;
}
.tiles-col-content-3::before{
  content: "";
  width: 70px;
    height: 26px;
    background: #7CC1D6;
    box-shadow: -1px 3px 6px 1px #8f8e8e1f, -4px 2px 6px 1px #ffffff, inset 1px 0px 2px 0px #00000030;
    position: absolute;
    left: -68px;
    bottom: 30px;
    z-index: 1;
    border-radius: 20px 0px 0px 20px;
}
.tiles-col-content-4{
  position: relative;
  background: #57B5B7 !important;
    padding: 24px 24px;
    overflow: unset !important;
}
.tiles-col-content-4::before{
  content: "";
  width: 70px;
    height: 26px;
    background: #57B5B7;
    box-shadow: -1px 3px 6px 1px #8f8e8e1f, -4px 2px 6px 1px #ffffff, inset 1px 0px 2px 0px #00000030;
    position: absolute;
    left: -68px;
    bottom: 30px;
    z-index: 1;
    border-radius: 20px 0px 0px 20px;
}

.tiles-content-header{
  display: flex;
    justify-content: space-between;
}
.tiles-content-header img{
  width: 70px;
}
.tiles-content-counr{
  color: #fff;
    font-size: 40px;
    font-weight: 800;
}
.tiles-content-footer{
  padding-top: 2rem;
    font-size: 16px;
    font-weight: 500;
    color: #000;
}
.tiles-content-footer b{
  text-transform: uppercase;
}


.data-progress-row{
  display: flex;
  justify-content: center;
  grid-column-gap: 14px;
  }
.data-progress-circle{
  width: 200px;
  height: 200px;
  background: #F0F0F0;
  border-radius: 100px;
  box-shadow: inset 0px 0px 1px 5px #fff, 0px 0px 7px 14px #60606040, 0px 0px 0px 20px #ffffff2b, 0px 0px 18px 33px #8d8d8d38;
  position: relative;
  display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.data-progress-1::before{
  content: "";
  width: calc(100% + 38px);
    height: calc(50% + 38px);
    position: absolute;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    border: 25px solid #c0f26fcc;
    border-bottom: 0;
    top: -18px;
    left: -18px;
}
.data-progress-2::before{
  content: "";
  width: calc(100% + 38px);
    height: calc(50% + 38px);
    position: absolute;
    border-bottom-left-radius: 120px;
    border-bottom-right-radius: 120px;
    border: 25px solid #a4dfa4a6;
    border-top: 0;
    bottom: -18px;
    left: -18px;
}
.data-progress-3::before{
  content: "";
  width: calc(100% + 38px);
    height: calc(50% + 38px);
    position: absolute;
    border-top-left-radius: 120px;
    border-top-right-radius: 120px;
    border: 25px solid #79bfd28f;
    border-bottom: 0;
    top: -18px;
    left: -18px;
}

.data-progress-4::before{
  content: "";
  width: calc(100% + 38px);
    height: calc(50% + 38px);
    position: absolute;
    border-bottom-left-radius: 120px;
    border-bottom-right-radius: 120px;
    border: 25px solid #57b6b894;
    border-top: 0;
    bottom: -18px;
    left: -18px;
}

.data-progress-circle label{
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  line-height: 18px;
  text-transform: uppercase;
}
.data-progress-circle p{
  margin: 0;
    font-size: 24px;
    font-weight: 800;
}
.founders-left{
  height: 500px;
}
.founders-left img{
  width: 100%;
    height: 100%;
    object-fit: cover;
}
.founders-profile{
  width: 200px;
  height: 200px;
  position: relative;
}
.founders-profile::before{
  content: "";
  position: absolute;
    width: 80px;
    height: 80px;
    background: #A4E0A3;
    left: -25px;
    top: 8px;
    z-index: -1;
    border-radius: 100px;
}
.founders-profile::after{
  content: "";
  position: absolute;
  width: 150px;
  height: 150px;
  background: #7CC0D5;
  right: -85px;
  bottom: -10px;
  z-index: -1;
  border-radius: 100px;
}

.founders-profile img{
  object-fit: cover;
  width: 200px !important;
  height: 200px;
  border-radius: 100px;
}
.founder-name{
  color: #12284c;
    font-size: 22px;
    font-weight: 800;
    line-height: 22px;
}
.founder-name span{
  font-size: 16px;
}
.founder-description p{
  font-size: 15px;
    font-weight: 700;
    color: #000;
    line-height: 18px;
}
.founder-social-link ul li i{
  color: #007AB5;
  cursor: pointer;
}
.founders-section{
  position: relative;
}
.founder-main-title{
  position: absolute;
  left: 10%;
  top: 12%;
  z-index: 2;
}

.founders-carousel .owl-dots{
  position: absolute;
    left: 0;
    bottom: 40px;
    width: 30%;
    text-align: left;
    padding: 8px 8px !important;
}
.founders-carousel .owl-dots button, .advisors-carousel .owl-dots button{
  border-radius: 50%;
    background-color: rgb(255 255 255) !important;
    box-shadow: inset -5px -4px 8px 0px rgb(255 255 255 / 66%), inset 3.564px 1.816px 8px 0px rgb(126 126 126 / 48%);
    width: 20px;
    height: 20px;
    margin-right: 14px;
    position: relative;
}
.founders-carousel .owl-dots button.active::before, .advisors-carousel .owl-dots button.active::before{
  content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background: #07B4FE;
    border-radius: 100px;
    top: 4px;
    left: 4px;
}

.carousel-nav-hide .owl-nav{
display: none !important;
}

.advisors-profile{
  width: 100%;
    overflow: hidden;
    position: relative;
}
.advisors-profile img{
  width: 400px;
    height: 400px;
    object-fit: cover;
    position: absolute;
    left: -10px;
    top: -78px;
    clip-path: circle(65.4% at 31% 29%);
}
.advisors-details label{
  color: #12284c;
  font-size: 22px;
  font-weight: 800;
  line-height: 22px;
}
.advisors-details p{
  font-size: 15px;
    font-weight: 700;
    color: #000;
    line-height: 18px;
}
.advisors-details p a{
  color: #000 !important;
  text-decoration: unset !important;
  font-weight: 700;
}
.advisors-details{
  padding: 30px 10px;
}
.advisors-row{
  display: flex;
}
.advisors-carousel{
  background-color: rgb(240 240 240);
    box-shadow: inset -1px 2px 2px #ffffffcf, inset 0px 0px 0px #ffffff00, 2px 3px 6px 1px #8f8e8e30, -2px -1px 6px 1px #ffffff;
    border-radius: 20px;
    overflow: hidden;
}
.advisors-carousel .owl-dots{
  position: absolute;
    right: 0;
    bottom: 40px;
    width: 30%;
    text-align: right;
    padding: 8px 8px !important;
}

.advisors-social-link ul li i{
  color: #007AB5;
  cursor: pointer;
  font-size: 22px;
}


/* help  */
.help-search{
  margin: 0 auto;
  width: 30%;
}
.help-search-field{
  box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
  padding: 8px 14px;
  border-radius: 20px;
  text-align: center;
}
.help-search-field i{
  margin-right: 12px;
  font-size: 13px;
}
.help-search-field .input-help, .help-search-field .input-help:focus, .input-help:focus-visible{
  background: transparent;
    border: none;
    outline: unset;
}

.acc-title{
  font-size: 18px;
  color: #12284c;
  font-weight: 600;
  margin: 0;
}
.acc-link{

}
.acc-link a{
  font-size: 18px;
    color: #12284c;
    font-weight: 700;
    text-decoration: none;
}

.tab-section{

}
.tab-section .nav-tabs{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 60px;
    grid-row-gap: 20px;
    border: none !important;
}
.tab-section .nav-tabs .nav-link{
  box-shadow: 3px 4px 6px 2px #00000021, -3px -2px 6px 2px #ffffff21, inset -1px 0px 6px 2px #e5e5e517;
  border-radius: 50px;
  color: #11274e;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  border: none !important;
}
.tab-section .nav-tabs .nav-link.active{
  background: #13284D;
  color: #fff;
}

.theme-divider{
  width: 100%;
  height: 11px;
  box-shadow: inset 6px 2px 5px #ababab91, inset -4px -4px 5px #fffffff5, -1px 1px 6px 1px #b1b1b130, -2px -1px 6px 1px #ffffff;
  border-radius: 100px;
  background: #f0f0f0;
}


/* blog  */
.find-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.find-container .find-col-1, .find-container .find-col-2{
  box-shadow: inset 3px 4px 5px #ebebebfa, inset -4px -4px 5px #f0f0f0, 2px 3px 6px 1px #8f8e8e30, -2px -1px 6px 1px #ffffff;
    border-radius: 20px;
    background: #f0f0f0;
}

.find-container .find-col-1{
  width: 60%;
  padding: 10px 12px;
    border-radius: 20px 0px 0px 20px;
    margin-right: 10px;
}
.find-container .find-col-2{
  width: 40%;
  padding: 10px 12px;
    border-radius: 0px 20px 20px 0px;
}


.search-input{
  display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
    padding: 8px 14px;
    border-radius: 100px;
}
.search-input i{
  font-size: 16px;
    opacity: .5;
}
.search-input input, .search-input input:focus-within{
  width: 88%;
    background: transparent;
    border: none;
    margin: 0 auto;
    padding: 0px 10px;
    outline: none !important;
}

.blog-search-btn{
  background: #7DC1D6;
    padding: 4px 16px;
    border-radius: 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    color: #11274E;
    cursor: pointer;
}

.filter-dropdown{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 2px;
}
.filter-dropdown .dropdown{
  width: 100%;
}
.filter-dropdown .dropdown-field {
  width: 98%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-dropdown .dropdown-field .select-value{
  box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
    border-radius: 20px 0px 0px 20px;
    background: #f0f0f0;
    border: none;
    color: #000;
    font-weight: 800;
    text-transform: uppercase;
    width: 100%;
    padding: 8px 5px;
    margin-right: 8px;
    position: relative;
    z-index: 0;
}
.filter-dropdown .dropdown-field .dropdown-icon{
  box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
  border-radius: 0px 20px 20px 0px;
  background: #f0f0f0;
  border: none;
  color: #000;
  font-weight: 800;
  text-transform: uppercase;
  width: 60px;
  padding: 5px 5px;
  font-size: 20px;
}

.filter-dropdown .dropdown-field::after{
  content: "";
  display: none !important;
}
.filter-dropdown .dropdown-menu{
width: 100%;
}
.blog-filter-btn{
  background: #7DC1D6;
    padding: 4px 22px;
    border-radius: 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
    color: #11274E;
    cursor: pointer;
}
/* .dropdown-icon{
  position: absolute;
  box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
  border-radius: 0px 20px 20px 0px;
  right: 0;
  top: 0;
  width: 40px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
} */


.blog-main-tile{
  font-size: 42px;
  font-weight: 500;
  color: #000;
}
.blog-badge{
  background: #CAFC72;
  display: inline-block;
  padding: 2px 12px;
  font-size: 14px;
  font-weight: 800;
  border-radius: 8px;
}
.blog-own-row{
  display: flex;
  align-items: center;
  flex-direction: row;
}
.blog-own-img{
  width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 100px;
    background: #8b8b8b;
    margin-right: 6px;
}
.blog-own-img img{
  object-fit: cover;
    width: 100%;
    height: 100%;
}
.blog-own-detail{

}
.blog-own-name{
  margin: 0;
  font-size: 15px;
  color: #000;
  font-weight: 500;
}
.blog-own-position{
  margin: 0;
  font-size: 12px;
  font-weight: 800;
}
.blog-own-description,
.blog-own-description p{
  font-size: 15px;
  color: #000 !important;
  font-weight: 600 ;
  line-height: 18px;
}
.history-detail .fa-heart{
  background: linear-gradient(136deg, rgb(255 255 255 / 21%) 0%, #d4d4d4 30%, #b7b7b7bd 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}
.history-detail .fa-heart.active{
  background: linear-gradient(136deg, #FA1768 0%, #FA1768 30%, #FA1768 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blog-history{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.blog-badge-sm{
  background: #11274E;
    color: #fff;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 15px;
}
.history-detail{
  margin: 0;
    font-size: 15px;
    color: #000;
}

.blog-template-img{
    height: 100%;
    min-height: 100px;
    max-height: 100%;
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: -3.564px -1.816px 16px 0px rgb(255 255 255 / 0%), 3.564px 1.816px 16px 0px rgb(126 126 126 / 48%);
    border: 1px solid #ebebeb;
    position: relative;
}
.blog-template-img img,
.blog-template-img video{
	width: 100%;
    height: 100%;
    object-fit: cover;

}
.blog-main-tile-sm{
  font-size: 30px;
  font-weight: 500;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.h-300{
  height: 300px !important;
}

.blog-editor-section{
  background: #E9E7E7;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.blog-editor-card{
  height: 280px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}
.blog-editor-img{
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
  -o-transition: all 0.3s;
	transition: all 0.3s;
  height: 100%;
  top: 0;
  position: relative;
  z-index: 1;
}

.front-title{
  position: fixed;
  left: 20px;
  top: 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
  -o-transition: all 0.3s;
	transition: all 0.3s;
}

.blog-editor-img img{
  -webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
  -o-transition: all 0.3s;
	transition: all 0.3s;
  height: 100%;
width: 100%;
object-fit: cover;
}
.blog-editor-back{
  background-color: #F2F0F1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.editor-back-description{
  font-size: 15px;
    color: #000;
    font-weight: 600;
    line-height: 18px;
    padding: 50px 22px;
}

.blog-editor-card:hover .blog-editor-img{
  top: 50%;
}
.blog-editor-card:hover .front-title{
color: #000;
}

.thumbs-pos li{
  box-shadow: inset 3px 4px 5px #d9d9d9fa, inset -4px -4px 5px #ffffffad, 5px 4px 6px 1px #ff00e900, -2px -1px 6px 1px #7fc2d700;
    width: 30px;
    height: 25px;
    text-align: center;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.thumbs-pos li i{
  opacity: .7;
}

.quotes-card{
  display: flex;
    box-shadow: inset 3px 4px 5px #ebebebfa, inset -4px -4px 5px #f0f0f0, 2px 3px 6px 1px #8f8e8e30, -2px -1px 6px 1px #ffffff;
    border-radius: 20px;
}
.quotes-content{
	margin: 2rem;
    display: flex;
    align-items: center;
    position: relative;
	width: 70%;
}
.quotes-content p{
  font-size: 22px;
  font-weight: 900;
  color: #11274e;
  line-height: 22px;
  margin: 0;
}
blockquote {
	font-family: MD Primer Bold,Rubik,Lato,Lucida Grande,Lucida Sans Unicode,Tahoma,Sans-Serif;
	font-size: 18px;
	margin: 0.25em 0;
	padding: 0.35em 40px;
	line-height: 1.45;
	position: relative;
	color: #11274e;
	}
	
	blockquote:before {
		display: block;
		padding-left: 10px;
		content: "\201C";
		font-size: 80px;
		position: absolute;
		left: -20px;
		top: -20px;
		color: #11274e;
	}
	blockquote:after {
		display: block;
		content: "\201D";
		font-size: 80px;
		position: absolute;
		right: -20px;
		bottom: -80px;
		color: #11274e;
	}
	
	blockquote cite {
	color: #999999;
	font-size: 14px;
	display: block;
	margin-top: 5px;
	}
	 
.quotes-img{
	padding: 24px;
    width: 30%;
    text-align: right;
    overflow: hidden;
}
.quotes-img img{
	width: 100%;
    object-fit: contain;
}
.shadow-box{
  box-shadow: inset 3px 4px 5px #ebebebfa, inset -4px -4px 5px #f0f0f0, 2px 3px 6px 1px #8f8e8e30, -2px -1px 6px 1px #ffffff;
    border-radius: 20px;
}

.circle-profile {
  width: 20%;
  padding: 20px 20px;
}
.circle-profile img{
  width: 170px;
    height: 170px;
    object-fit: cover;
    border-radius: 100px;
}
.author-content-align{
  display: flex;
    flex-direction: column;
    justify-content: center;
}