.blog-label {
    color: #5e6c84;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    float: none;
    margin: 0 16px 0 0;
    display: block;
    text-align: left;
    width: auto;
    padding: unset;
    width: 100%;
}